.imageContainer {
  display: flex;
  flex: 1;
  padding: 1em;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 150px;
}

.imageContainer:hover {
  filter: brightness(75%);
}

.clientSection {
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  max-height: fit-content;
  padding: 1em;
}

.client-header{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.client-name{
  margin-left: 25px;
}
