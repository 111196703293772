.socialIcon:hover {
  filter: brightness(75%);
  cursor: pointer;
}

.serviceHover:hover {
  filter: brightness(75%);
}

.serviceSection {
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  max-height: fit-content;
  padding-left: 5%;
  padding-right: 5%;
}

.moibleServiceSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  max-height: fit-content;
  padding-left: 5%;
  padding-right: 5%;
}

.serviceImageContainer {
  display: flex;
  flex: 1;
  padding: 1em;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.serviceParagraphSection {
  width: 75%;
  text-align: center;
  flex: 1;
}

.featuredClientImageContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.H1Header {
  font-family: "Source Code Pro", Arial, Helvetica, sans-serif;
  padding-top: 3px;
}
