.scrollToTopImageDiv {
  position: fixed;
  width: 100%;
  left: 85%;
  bottom: 40px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  color: black;
}

.link:hover {
  cursor: pointer;
}
