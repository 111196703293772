@font-face {
  font-family: SourceCodeProMedium;
  src: url(../fonts/SourceCodePro-Medium.ttf);
}

@font-face {
  font-family: SourceSansProRegular;
  src: url(../fonts/SourceSansPro-Regular.ttf);
}

.paragraphTextStyle {
  font-family: "SourceSansProRegular", Arial, Helvetica, sans-serif;
  font-size: 1.25em;
  color: rgb(4, 46, 62);
}

.paragraphSnippetStyle {
  font-family: "SourceSansProRegular", Arial, Helvetica, sans-serif;
  font-size: 1.1em;
  text-align: center;
  padding: 5px;
  color: rgb(4, 46, 62);
}

.headerTextStyle {
  font-family: "SourceCodeProMedium", Arial, Helvetica, sans-serif;
  padding-top: 3px;
  text-align: center;
  color: #064860;
}

.pageMainDiv {
}

.centerParagraph {
  text-align: center;
}

.onePadding {
  padding: 1em;
}
