@font-face {
  font-family: SourceCodeProMedium;
  src: url(../fonts/SourceCodePro-Medium.ttf);
}

.navItemSelected {
  color: #d65e38;
  text-decoration: none;
  font-family: "SourceCodeProMedium", Arial, Helvetica, sans-serif;
}
.navItemSelected:hover {
  color: #d65e38;
  text-decoration: none;
}

.navItem {
  color: rgb(4, 46, 62);
  text-decoration: none;
  font-family: "SourceCodeProMedium", Arial, Helvetica, sans-serif;
}
.navItem:hover {
  color: #d65e38;
  animation: fadein 0.4s;
  -moz-transition: fadein 0.4s;
  -o-transition: fadein 0.4s;
  -webkit-transition: fadein 0.4s;
}

/* KeyFrames for color fading nav items on hover */
@keyframes fadein {
  from {
    color: black;
  }
  50% {
    color: rgb(118, 118, 118);
  }
  to {
    color: #d65e38;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    color: black;
  }
  50% {
    color: rgb(118, 118, 118);
  }
  to {
    color: #d65e38;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    color: black;
  }
  50% {
    color: rgb(118, 118, 118);
  }
  to {
    color: #d65e38;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    color: black;
  }
  50% {
    color: rgb(118, 118, 118);
  }
  to {
    color: #d65e38;
  }
}
