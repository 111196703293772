.contactingUs {
  padding: 0 4vw 0 4vw;
  margin: 0 2vw 0 2vw;
}

.getInTouch {
  background-color: white;
  padding: 0 2vw 0 4vw;
  margin: 0 2vw 0 2vw;
  background-color: white;
  color: #064860;
}

.formInput {
  background-color: transparent;
  border-style: none;
  border-bottom: 1px solid grey !important;
  width: 85%;
  color: #064860;
}

.formTextArea {
  background-color: transparent;
  border-style: none;
  border-bottom: 1px solid grey;
  width: 85%;
  height: 10em;
  color: #064860;
  resize: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.imageOnHover:hover {
  filter: brightness(75%);
  cursor: pointer;
}
