/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.pageBackground {
  min-height: 98vh;
}

.globalFont {
  font-family: "Arial";
  line-height: 1.8;
}
